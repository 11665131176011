import React from 'react';
import Loader from 'react-loader-spinner';

const LoaderComponent = () => (
  <div className="loader">
    <Loader
      type="Puff"
      color="#3C3F58"
      height={100}
      width={100}
      visible={true}
    />
  </div>
)

export default LoaderComponent;